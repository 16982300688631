		
<template>
<div class="outer-wrapper">
    <div class="auth">

		<div class="inner-wrapper">

			<div class="heading-section">
				<h1 class="heading3">Create a password</h1>
			</div>
			
			<form @submit.prevent="handleSubmit">
				<div class="form-group">
					<label htmlFor="password">Password</label>
					<input type="password" v-model="newUser.password" name="password" class="form-control" :class="{ 'is-invalid': submitted && errors.has('password') }" />
					<div v-if="submitted && errors.has('password')" class="invalid-feedback">{{ errors.get('password')[0] }}</div>
				</div>
				<div class="form-group">
					<label htmlFor="password">Re-enter Password</label>
					<input type="password" v-model="newUser.passwordReenter" name="passwordReenter" class="form-control" :class="{ 'is-invalid': submitted && errors.has('passwordReenter') }" />
					<div v-if="submitted && errors.has('passwordReenter')" class="invalid-feedback">{{ errors.get('passwordReenter')[0] }}</div>
				</div>
				<div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>

				<Loader :loading="isLoading" />
				<div v-if="!isLoading" class="form-group btn-container">
					<button class="button btn-primary" >Continue</button>
				</div>
			</form>
    	</div>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router';
import account from '@/helpers/account.js';
import Loader from '@/components/Loader'

export default {
    data () {
        return {
			submitted: true,
			errors: new Map(),
			isLoading: false,
        }
    },
    computed: {
		...mapState('account', ['status', 'newUser']),
		...mapState({
			alert: state => state.alert,
		}),
    },
	components: {
		Loader,
	},
    methods: {
        ...mapActions('account', ['register']),
		...mapActions({
			alertError: "alert/error",
			alertSuccess: "alert/success",
		}),
        async handleSubmit(e) {
            if(this.checkForm()) {
				this.isLoading = true;
				var success = await this.register(this.newUser).then((success) => {
					if(success) {
						this.alertSuccess("Registered successfully");
						router.push({ name: "Register Success", query: { redirect: this.$route.query.redirect }});
					}
				}).catch((error) => {
					this.alertError(error);
				});
				
				this.isLoading = false;
			}
		},
		checkForm() {
			this.errors = new Map();
			
			let { passwordErrors, passwordReenterErrors } = account.validatePassword(this.newUser.password, this.newUser.passwordReenter)

			if(passwordErrors.length > 0) {
				this.errors.set('password', passwordErrors);
			}
			if(passwordReenterErrors.length > 0) {
				this.errors.set('passwordReenter', passwordReenterErrors);
			}

			if (!this.errors.size > 0) {
				return true;
			}
			return false;
			e.preventDefault();
		},
	},
	created() {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: { name: 'Register Email', query: { redirect: this.$route.query.redirect }},
			},
			right: {
				type: 'exit',
				route: { name: 'Home' },
			},
		});

		if(this.newUser.firstName == null || this.newUser.lastName == null) {
			router.push({ name: "Register", query: { redirect: this.$route.query.redirect }})
		}
		if(this.newUser.email == null || this.newUser.email == null) {
			router.push({ name: "Register Password", query: { redirect: this.$route.query.redirect }})
		}
	}
};
</script>	


		